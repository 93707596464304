<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    :scrollable="$vuetify.breakpoint.smAndDown"
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Documento Equivalente</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon @click="close()"> mdi-close </v-icon>
      </v-toolbar>
      <v-card-text class="pa-3">
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              v-model="send_invoice.to[0].email"
              label="To"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              v-model="send_invoice.cc[0].email"
              label="CC"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              v-model="send_invoice.pdf_base64_bytes"
              label="pdf_base64_bytes"
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-0">
        <v-toolbar>
          <v-spacer></v-spacer>
          <v-btn color="success" x-large @click="send_doc()"> Enviar </v-btn>
        </v-toolbar>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { fe_ws } from "../services/webserver.js";
export default {
  props: {
    doc: {
      type: Object,
      required: false,
    },
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  mounted() {},
  data() {
    return {
      loading_status: false,
      send_invoice: {
        to: [
          {
            email: "user@example.com",
          },
        ],
        cc: [
          {
            email: "user@example.com",
          },
        ],
        pdf_base64_bytes: "string",
        annexes: {
          name: "string",
          base64_bytes: "string",
        },
        options: {
          attached_pdf: false,
        },
      },
    };
  },
  methods: {
    close() {
      this.$emit("close", true);
    },
    get_doc_uuid() {
      this.loading_status = true;
      var qry = {
        method: "POST",
        nit: this.doc.store.identification_id,
        uuid: this.doc.header.cufe,
      };
      this.loading_status = true;
      fe_ws("get_doc_uuid", qry, (data) => {
        this.loading_status = false;
        this.send_invoice.to[0].email = data.payload.customer.email;
        this.send_invoice.pdf_base64_bytes = data.pdf_base64_bytes;
        //console.log(data);
      });
    },
    send_doc() {
      this.loading_status = true;
      var qry = {
        method: "POST",
        nit: this.doc.store.identification_id,
        uuid: this.doc.header.cufe,
        data: this.send_invoice,
      };
      this.loading_status = true;
      console.log(qry);
      fe_ws("send_invoice", qry, (data) => {
        this.loading_status = false;
        console.log(data);
      });
    },
  },
  watch: {
    // doc: function (e) {
    //   console.log(e);
    // },
    dialog: function () {
      if (this.dialog) {
        this.get_doc_uuid();
      }
    },
  },
};
</script>
