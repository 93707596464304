<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm12 md10 lg10>
        <v-form v-model="validDoc" ref="docForm" @submit="send_doc">
          <v-sheet color="white" elevation="0">
            <v-row class="pa-1">
              <v-col cols="12" sm="8">
                <h3>{{ doc_type.label }}</h3>
                <b v-if="this.doc.doc_code">{{ this.doc.doc_code }}</b>
                <br />
                {{ this.doc.issue_date }}
              </v-col>
              <v-col cols="12" sm="4" align="right">
                <v-select
                  v-model="doc.status"
                  hide-details
                  :items="status_lst"
                  label="Estado"
                  placeholder="Estado"
                  :rules="f_required"
                  outlined
                  dense
                  @change="changeStatus"
                />
              </v-col>
              <v-col cols="12" sm="6" v-if="!$vuetify.breakpoint.xsOnly">
                <v-card height="100%" elevation="1">
                  <v-card-title>{{ this.doc.pos.name }}</v-card-title>
                  <v-card-text>
                    {{
                      get_name_typeId(this.doc.pos.type_id) +
                      ": " +
                      this.doc.pos.identification_id
                    }}
                    <br />
                    {{
                      this.doc.pos.address +
                      " , " +
                      this.doc.pos.city +
                      " - " +
                      this.doc.pos.province
                    }}
                    <br />
                    {{ "Email: " + this.doc.pos.email + " / Tel:" + this.doc.pos.mobile }}
                    <br />
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6">
                <v-card height="100%" elevation="1">
                  <v-card-title>
                    <span v-if="this.doc.party.name">{{ this.doc.party.name }}</span>
                    <span v-else>{{ this.docset.to }}</span>
                    <v-spacer></v-spacer>
                    <v-btn
                      fab
                      color="success"
                      x-small
                      @click="client_dialog = !client_dialog"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text v-if="this.doc.party.name">
                    {{
                      get_name_typeId(this.doc.party.type_id) +
                      ": " +
                      this.doc.party.identification_id
                    }}
                    <br />
                    {{
                      this.doc.party.address +
                      " , " +
                      this.doc.party.city +
                      " - " +
                      this.doc.party.province
                    }}
                    <br />
                    {{
                      "Email: " + this.doc.party.email + " / Tel:" + this.doc.party.mobile
                    }}
                    <br />
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" md="12">
                <v-data-table
                  :headers="headers_cart[$vuetify.breakpoint.xsOnly]"
                  :items="doc.items"
                  :items-per-page="-1"
                  class="elevation-1"
                  mobile-breakpoint="0"
                  item-key="ean"
                  :loading="loading_status"
                  loader-height="2"
                  loading-text="Cargando ..."
                  hide-default-footer
                  @click:row="editItem"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>{{ docset.concept }}</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <!-- <v-btn fab icon small @click="dialog_new = !dialog_new">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn> -->
                      <v-btn
                        fab
                        color="success"
                        x-small
                        @click="search_dialog = !search_dialog"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-toolbar>
                  </template>

                  <template
                    v-slot:[`item.product_name`]="{ item }"
                    @click="editItem(item)"
                  >
                    <span>
                      {{ item.product_name }}
                      <br />
                      <span v-if="item.note" v-text="item.note" />
                      <small v-if="item.shipping_time > 1">
                        Tiempo de entrega: {{ item.shipping_time }} días hábiles.
                      </small>
                    </span>
                  </template>

                  <template v-slot:[`item.qty`]="{ item }">
                    <span>
                      {{ item.qty }}
                    </span>
                  </template>
                  <template v-slot:[`item.size`]="{ item }">
                    <span>
                      {{ item.size + " " + item.uom }}
                    </span>
                  </template>
                  <template v-slot:[`item.tax_base`]="{ item }">
                    {{ "$" + parseFloat(item.tax_base).toLocaleString(2) }}
                  </template>

                  <template v-slot:[`item.tax_value`]="{ item }">
                    {{ "$ " + parseFloat(item.tax_value).toLocaleString(2) }}
                  </template>

                  <template v-slot:[`item.tItem`]="{ item }">
                    <span>
                      {{ "$ " + parseFloat(item.tItem).toLocaleString(2) }}
                      <br />
                      <small
                        class="red--text text-decoration-line-through"
                        v-if="item.discount > 0"
                      >
                        {{ "$ " + (item.price * item.qty * item.pack).toLocaleString(2) }}
                      </small>
                    </span>
                  </template>
                  <!-- <template v-slot:header.actions>
                <button fab @click="search_dialog = !search_dialog">
                  <v-icon>mdi-plus</v-icon>
                </button>
              </template> -->
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small @click="delete_item(item)">mdi-delete</v-icon>
                  </template>

                  <template slot="body.append">
                    <tr>
                      <th>Totales</th>

                      <th class="text-center">
                        {{ sumField(doc.items, "qty").toLocaleString(2) }}
                      </th>

                      <th v-if="!$vuetify.breakpoint.xsOnly" class="text-right">
                        {{ sumField(doc.items, "tax_base").toLocaleString(2) }}
                      </th>
                      <th v-if="!$vuetify.breakpoint.xsOnly" class="text-right">
                        {{ sumField(doc.items, "tax_value").toLocaleString(2) }}
                      </th>
                      <th class="text-right">
                        {{ sumField(doc.items, "tItem").toLocaleString(2) }}
                      </th>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
              <!-- <v-col cols="12" md="12" v-else>
          <v-data-table
            :headers="headers_cart"
            :items="doc.items"
            :items-per-page="5"
            class="elevation-1"
            dense
            calculate-widths
            mobile-breakpoint="0"
            item-key="ean"
            :loading="loading_status"
            loader-height="2"
            loading-text="Cargando ..."
            @click:row="editItem"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Descripción</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn fab icon x-small @click="search_dialog = !search_dialog">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-toolbar>
            </template>

            <template v-slot:[`item.product_name`]="{ item }">
              {{ item.product_name }}
              <br />
              <span v-if="item.description" v-html="item.description"></span>
            </template>

            <template v-slot:[`item.pack`]="{ item }">
              {{ item.pack + " " + item.uom }}
            </template>

            <template v-slot:[`item.tItem`]="{ item }">
              {{ "$ " + parseFloat(item.tItem).toLocaleString(2) }}
            </template>

            <template slot="body.append">
              <tr>
                <th>Totales</th>
                <th></th>
                <th class="text-center">
                  {{ sumField(doc.items, "qty").toLocaleString(2) }}
                </th>
                <th class="text-right">
                  {{ this.totals.costs.toLocaleString(2) }}
                </th>
              </tr>
            </template>
          </v-data-table>
        </v-col> -->
              <v-col cols="12" sm="6" height="100%">
                <v-data-table
                  :headers="headers_payments"
                  :items="doc.payments"
                  hide-default-footer
                  mobile-breakpoint="0"
                  sort-by="name"
                  class="elevation-1"
                  :loading="loading_status"
                  loader-height="2"
                  loading-text="Cargando ..."
                  flat
                  elevation="0"
                >
                  <template #top>
                    <!-- <v-toolbar flat>
                <v-toolbar-title>{{docset.paymode}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  fab
                  icon
                  color="success"
                  @click="payment_dialogo = !payment_dialogo"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-toolbar> -->
                  </template>
                  <template v-slot:header.actions>
                    <button fab @click="payment_dialogo = !payment_dialogo">
                      <v-icon>mdi-plus</v-icon>
                    </button>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small @click="delete_payment(item)">mdi-delete</v-icon>
                  </template>
                  <template slot="body.append">
                    <tr>
                      <th>Totales</th>
                      <th class="text-right">
                        {{ sumField(doc.payments, "payment_value").toLocaleString(2) }}
                      </th>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="12" sm="6" height="100%">
                <v-textarea
                  v-model="doc.note"
                  outlined
                  clearable
                  auto-grow
                  label="Observaciones"
                ></v-textarea>
              </v-col>
              <!-- <v-col cols="12" sm="12">
                <v-row>
                  <v-col cols="6" sm="4">
                    <v-text-field
                      v-model="doc.delivery_date"
                      type="date"
                      hide-details="auto"
                      label="Fecha de entrega"
                      class="center-input"
                      outlined
                      required
                      dense
                    />
                  </v-col>
                 
                  <v-col cols="6" sm="4">
                    <v-combobox
                      v-model="doc.delivery_time"
                      hide-details="auto"
                      label="Horario de entrega"
                      :items="delivery_times"
                      :rules="f_required"
                      class="center-input"
                      outlined
                      required
                      dense
                    />
                  </v-col>
                  <v-col cols="12" sm="4" align="right"> </v-col>
                </v-row>
              </v-col> -->
              <!-- <v-col cols="12" sm="6">
            <v-row>
              <v-col>
                <v-text-field
                  v-model="doc.qty"
                  outlined
                  label="Cantidad"
                  readonly
                  class="center-input"
                  append-icon="mdi-save"
                  dense
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="doc.total"
                  outlined
                  label="Total"
                  readonly
                  class="center-input"
                  append-icon="mdi-save"
                  dense
                />
              </v-col>
            </v-row>
          </v-col> -->
              <v-col cols="12" sm="3">
                <VueQr
                  :text="pdf.header.cufe"
                  :size="120"
                  autoColor
                  v-if="doc.doc_type === 'FE'"
                />
              </v-col>
              <v-col cols="12" sm="3" v-if="doc.doc_type === 'FE'">
                <v-btn x-large @click="openURL(pdf.header.cufe)">
                  <v-icon>mdi-file-pdf-box</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" sm="3" v-if="doc.doc_type === 'FE'">
                <v-btn x-large @click="document_uuid(pdf)">
                  <v-icon>mdi-send</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-bottom-navigation dark color="secondary" app>
              <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
              <v-btn to="docs" v-if="doc.doc_code">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <!-- <v-btn @click="reset_doc">
            <span>Reset Doc</span>
            <v-icon>mdi-text-box-plus-outline</v-icon>
          </v-btn> -->

              <v-btn @click="Wompi_status" v-if="doc.doc_code">
                <span>Wompi</span>
                <v-icon>mdi-credit-card-search-outline</v-icon>
              </v-btn>

              <v-btn @click="e_invoice" v-if="doc.doc_type === 'FE'">
                <span>DIAN</span>
                <v-icon>mdi-file-pdf-box</v-icon>
              </v-btn>

              <v-btn @click="show_events" v-if="doc.doc_code">
                <span>Events</span>
                <v-icon>mdi-calendar-week-outline</v-icon>
              </v-btn>

              <v-btn @click="print_invoice">
                <span>PDF</span>
                <v-icon>mdi-file-pdf-box</v-icon>
              </v-btn>

              <v-btn type="submit" :disabled="loading_status">
                <span>Guardar</span>
                <v-icon>mdi-content-save-outline</v-icon>
              </v-btn>
            </v-bottom-navigation>
          </v-sheet>
        </v-form>
      </v-flex>
    </v-layout>

    <v-snackbar
      v-model="skuError"
      :timeout="timeout_error"
      absolute
      centered
      dark
      color="red"
      elevation="24"
    >
      Producto no encontrado en el inventario ...
    </v-snackbar>

    <v-dialog
      v-model="doctype_dialog"
      max-width="500"
      :scrollable="$vuetify.breakpoint.smAndDown"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
    >
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat rounded>
            <v-toolbar-title>Tipo Documento</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon fab @click="doctype_dialog = !doctype_dialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-for="(child, index) in docType" :key="index" cols="12" md="6">
              <v-card
                elevation="10"
                height="100%"
                class="text-center d-flex flex-column align-center justify-center ma-2"
              >
                <v-list-item @click="changeDocType(child)">
                  <v-list-item-content>
                    <v-list-item-title v-text="child.label" class="text-wrap" />
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="doctype_dialog = !doctype_dialog">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="events_dialog"
      max-width="500"
      :scrollable="$vuetify.breakpoint.smAndDown"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
    >
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat rounded>
            <v-toolbar-title>Eventos</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon fab @click="events_dialog = !events_dialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="header_events[$vuetify.breakpoint.xsOnly]"
            :items="events_lst"
            :loading="loading_status"
            loader-height="2"
            loading-text="Cargando ..."
            mobile-breakpoint="0"
            locale="es-co"
          >
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="events_dialog = !events_dialog">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="edit_dialog"
      persistent
      max-width="480px"
      scrollable
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card>
        <v-img
          contain
          v-if="itemEdit.picture"
          aspect-ratio="2"
          class="white pa-0"
          width="100%"
          height="40vh"
          :src="getImagePhoto(itemEdit.account, itemEdit.picture)"
        >
          <v-app-bar flat color="transparent" class="pa-0">
            <v-spacer></v-spacer>
            <v-btn fab x-small top right dark @click="edit_dialog = !edit_dialog">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-app-bar>
        </v-img>
        <v-card-title class="pt-0">
          <h5>{{ itemEdit.product_name }}</h5>
        </v-card-title>
        <v-card-text>
          <span v-html="itemEdit.description"></span>
          <!-- <v-col cols="12" md="12" class="vueEditorCSS">
            <vue-editor
              v-model="itemEdit.description"
              :editorToolbar="customToolbar"
            ></vue-editor>
          </v-col> -->
          <!-- <v-row>
            <v-col cols="6" sm="4">
              <v-text-field
                @change="updateTotal"
                v-model="itemEdit.qty"
                label="Cantidad"
                placeholder="Cantidad"
                class="center-input"
                type="number"
                hide-details
                outlined
              />
            </v-col>

            <v-col cols="6" sm="4">
              <v-select
                v-model="itemEdit.uom"
                hide-details="auto"
                :rules="f_required"
                item-text="text"
                item-value="value"
                :items="uom_lst"
                label="UOM"
                outlined
              />
            </v-col>
            <v-col cols="6" sm="4">
              <v-text-field
                v-model="itemEdit.pack"
                @keyup="updateTotal"
                label="Pack"
                placeholder="Pack"
                class="center-input"
                type="number"
                hide-details
                outlined
              />
            </v-col>

            <v-col cols="6" sm="4" v-if="this.doc.doc_type !== 'ORP'">
              <v-text-field
                v-model="itemEdit.percent"
                @keyup="updateTotal"
                label="Descuento"
                placeholder="Descuento"
                class="center-input"
                type="number"
                hide-details
                outlined
                append-icon="mdi-percent-outline"
              />
            </v-col>

            <v-col cols="12" sm="8" v-if="this.doc.doc_type !== 'ORP'">
              <v-text-field
                @change="updateTotal"
                v-model="itemEdit.price"
                label="Valor"
                placeholder="Valor"
                class="center-input"
                type="number"
                hide-details
                outlined
                clearable
              />
            </v-col>
            <v-col cols="12" sm="8" v-else>
              <v-text-field
                @change="updateTotal"
                v-model="itemEdit.cost"
                label="Valor"
                placeholder="Valor"
                class="center-input"
                type="number"
                hide-details
                outlined
                clearable
              />
            </v-col>
          </v-row> -->
          <v-textarea
            v-model="itemEdit.note"
            class="pt-12"
            auto-grow
            label="Note"
            rows="1"
          />
        </v-card-text>

        <v-card-actions>
          <v-col cols="2">
            <v-btn icon color="red" @click="delete_item(itemEdit)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="5">
            <v-text-field
              v-model="itemEdit.qty"
              class="center-input"
              hide-details
              filled
              rounded
              Label="Qty"
              dense
              single-line
              prepend-inner-icon="mdi-minus"
              append-icon="mdi-plus"
              @click:prepend-inner="restQty"
              @click:append="addQty"
            />
          </v-col>
          <v-col cols="5">
            <v-btn block large color="success" @click="edit_dialog = !edit_dialog">
              $
              {{ parseFloat(itemEdit.tItem).toLocaleString() }}
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="client_dialog"
      max-width="600px"
      :scrollable="$vuetify.breakpoint.smAndDown"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title> Informacion de contacto </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon fab @click="client_dialog = !client_dialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-6">
          <v-row>
            <v-col cols="12" sm="6">
              <v-select
                v-model="doc.party.type_id"
                hide-details
                :items="id_lst"
                item-value="code"
                item-text="name"
                label="Tipo de documento"
                placeholder="Vendedor"
                outlined
                dense
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="doc.party.identification_id"
                label="Número de documento"
                ref="idNumber"
                clearable
                hide-details
                outlined
                @keyup="validateID"
                dense
              />
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="doc.party.name"
                label="Nombre Completo"
                hide-details
                outlined
                dense
              />
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field
                v-model="doc.party.address"
                label="Dirección"
                hide-details
                outlined
                dense
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                v-model="doc.party.city"
                hide-details="auto"
                label="Ciudad"
                :items="cities"
                item-text="name"
                item-value="code"
                :rules="f_required"
                outlined
                required
                dense
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="doc.party.mobile"
                label="Celular"
                hide-details
                outlined
                dense
              />
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field
                v-model="doc.party.email"
                label="Correo Electrónico"
                placeholder="Correo Electrónico"
                hide-details
                outlined
                dense
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                outlined
                v-model="doc.party.note"
                label="Observaciones"
                dense
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn large color="success" type="button" @click="party_submit">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <PartyForm
      table="tercero"
      :item="doc.party"
      :dialog="client_dialog"
      :newItem="newItem"
      @close="client_dialog = false"
      @refresh="refresh()"
    /> -->

    <v-dialog v-model="payment_dialogo" max-width="600px">
      <v-form v-model="payvalid" ref="payform" @submit="addPayment">
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>Registrar Pagos</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn fab icon @click="payment_dialogo = !payment_dialogo">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="pt-6">
            <v-row>
              <v-col cols="12" sm="12">
                <v-combobox
                  v-model="pay_mode"
                  :items="doc_type.fp"
                  return-object
                  item-value="codigo"
                  item-text="valor"
                  label="Forma de pago"
                  placeholder="Forma de pago"
                  outlined
                  hide-details
                />
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field
                  v-model="payment.payment_reference"
                  label="Documento / Referencia"
                  :error="payRefe"
                  @keyup="updateRefe"
                  required
                  outlined
                  hide-details
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="payment.payment_value"
                  label="Valor"
                  type="number"
                  class="right-input"
                  :error="payValue"
                  clearable
                  :rule="payRule"
                  outlined
                  required
                  hide-details
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="!payvalid" color="success" class="mr-4" type="submit">
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-navigation-drawer
      right
      class="navigation-drawer"
      :value="search_dialog"
      :height="this.best_height"
      :width="this.best_widht"
      app
    >
      <!-- <v-form v-model="search_valid" ref="searchform" @submit="search_inventory"> -->
      <template v-slot:prepend>
        <v-app-bar dark flat>
          <v-toolbar-title>Productos y Servicios</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn fab icon @click="search_dialog = !search_dialog">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-app-bar>
      </template>

      <!-- <v-expansion-panels tile width="100%">
        <v-expansion-panel v-for="(item, i) in categories" :key="i">
          <v-expansion-panel-header>
            <v-list-item tile>
              <v-list-item-content>
                <v-list-item-title>
                  <h3>{{ item }}</h3>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-expansion-panel-header>
          <v-expansion-panel-content> -->
      <!-- <v-data-table
              :headers="headers_lst[$vuetify.breakpoint.xsOnly]"
              :items="byCategories[item]"
              :items-per-page="-1"
              item-key="index"
              hide-default-footer
              sort-by="name"
              mobile-breakpoint="0"
              class="table-cursor pa-0"
              loading-text="Cargando ..."
              @click:row="add2cart"
            >
              <template v-slot:top></template>

              <template v-slot:[`item.product_name`]="{ item }">
                <b class="text-uppercase">{{ item.product_name }}</b
                ><br />
                <small>{{ item.note }}</small>
              </template>
              <template v-slot:[`item.tItem`]="{ item }" class="text-right">
                {{ item.tItem.toLocaleString() }}
              </template>
            </v-data-table> -->
      <!-- </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels> -->
      <template v-slot:default>
        <v-row class="pa-6">
          <!-- <v-col cols="12">
          <v-select
            v-model="category_selected"
            :items="categories"
            label="Categorias"
            outlined
            hide-details
          />
        </v-col> -->
          <template v-if="!category_selected">
            <v-col v-for="(child, index) in categories" :key="index" cols="6" md="4">
              <v-btn
                class="pt-12 pb-12"
                color="success"
                large
                block
                @click="category_selected = child"
              >
                <small class="text-center">
                  {{ child }}
                </small>
              </v-btn>
            </v-col>
          </template>
          <template v-else>
            <v-col
              v-for="(child, index) in byCategories[category_selected]"
              :key="index"
              cols="6"
              md="4"
            >
              <v-btn
                class="pt-12 pb-12"
                large
                block
                color="info"
                @click="add2cart(child)"
              >
                <small class="text-center">
                  {{ child.product_name + " $" + child.price }}
                </small>
              </v-btn>
            </v-col>
          </template>
        </v-row>
        <v-snackbar
          v-model="productAdded"
          :timeout="timeout"
          absolute
          centered
          dark
          color="orange"
          elevation="24"
        >
          Producto agregado ...
        </v-snackbar>
      </template>
      <!-- <v-data-table
              :headers="headers_lst[$vuetify.breakpoint.xsOnly]"
              :items="smart_lst"
              :items-per-page="20"
              class="elevation-0"
              mobile-breakpoint="0"
              item-key="ean"
              :loading="loading_status"
              loader-height="2"
              loading-text="Cargando ..."
              :search="search"
              @click:row="add2cart"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-text-field
                    v-model="search"
                    label="Buscar"
                    class="mx-4"
                    outlined
                    dense
                  />
                  <v-spacer></v-spacer>
                  <v-btn fab icon x-small @click="dialog_new = !dialog_new">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-toolbar>
              </template>
              <template v-slot:[`item.pack`]="{ item }">
                {{ item.pack + " " + item.uom }}
              </template>
            </v-data-table> -->

      <!-- </v-form> -->

      <template v-slot:append>
        <v-bottom-navigation dark grow app class="pos">
          <v-btn text large @click="category_selected = ''">
            <v-icon>mdi-apps</v-icon>
          </v-btn>
          <v-btn large>
            <span>{{ doc.qty.toLocaleString(2) }}</span>
            <b>QTY</b>
          </v-btn>

          <v-btn large>
            <span>{{ doc.total.toLocaleString(2) }}</span>
            <b>TOTAL</b>
          </v-btn>
        </v-bottom-navigation>
      </template>
    </v-navigation-drawer>

    <v-dialog v-model="st_dialog" max-width="800px">
      <v-card>
        <v-tabs show-arrows centered class="fixed-top-b">
          <v-tabs-slider></v-tabs-slider>
          <v-tab
            v-for="menu in menus"
            :key="menu"
            :href="'#tab-' + menu"
            @click="changeMenu(menu)"
          >
            {{ menu }}
          </v-tab>
        </v-tabs>
        <v-card-text>
          <v-row class="mt-6">
            <v-col
              class="col-6 col-sm-6 col-md-4"
              v-for="product in menu_selected"
              :key="product.ean"
            >
              <v-card height="100%" elevation="8" @click="add2cart_st(product.ean)">
                <v-img
                  cover
                  aspect-ratio="1"
                  max-height="300px"
                  :src="getImageURL(product.marca, product.refe)"
                ></v-img>
                <v-card-title dark>
                  <v-select
                    :items="get_options(product.options)"
                    label="Opciones"
                    item-value="ean"
                    item-text="description"
                  ></v-select>
                </v-card-title>
                <v-card-actions dark class="pa-2 card-actions bgb">
                  <v-spacer></v-spacer>
                  $ {{ product.precio }}
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <ProductForm
      table="raw_material"
      :dialog="dialog_new"
      :newItem="newItem"
      :categories="categories"
      store="000000"
      @close="dialog_new = false"
      :subcategories="subcategories"
      :brands="brands"
      suppliers="000000"
      @refresh="newProduct"
    />

    <dian_document_uuid :dialog="dialog_uuid" :doc="pdf" @close="dialog_uuid = false" />

    <!-- <docViewer
      :item="pdf"
      :dialog="dialog"
      @close="dialog = false"
      @refresh="refresh()"
    /> -->
  </v-container>
</template>

<script>
import { webserver, getIssueDate, fe_ws } from "../../services/webserver.js";
import createItem from "../../utils/createItem.js";
import createClient from "../../utils/createParty.js";
import createPayment from "../../utils/createPayment.js";
import dianResponse from "../../utils/dianResponse.js";
import createDoc from "../../utils/createDoc.js";
import docs_lst from "../../utils/docs.js";
// import PartyForm from "../components/ClientForm.vue";

// import docViewer from "../components/doc_viewer.vue";
// import createDoc from "../utils/create_doc.js";
// import { VueEditor } from "vue2-editor";
import ProductForm from "../../components/ServiceForm.vue";
import dian_document_uuid from "../../components/dian_document_uuid.vue";
import jsPDF from "jspdf";
import VueQr from "vue-qr";

export default {
  name: "Home",
  components: { ProductForm, VueQr, dian_document_uuid },
  mounted() {
    window.addEventListener("resize", this.onResize, { passive: true });
    if (this.$route.params.did) {
      this.get_doc(this.$route.params.did);
    } else {
      this.doctype_dialog = true;
    }

    if (this.$route.params.docType) {
      this.doc_type = this.$route.params.docType;
      this.changeDocType();
    }

    this.store = this.$store.getters.company;
    this.doc.pos = this.$store.getters.company;
    this.get_settings();
    //this.get_books("1");
  },
  data() {
    return {
      best_height: "100%",
      best_widht: "500",
      category_selected: "",
      events_dialog: false,
      events_lst: [],
      header_events: {
        false: [
          { text: "Fecha", value: "issue_date" },
          { text: "Evento", value: "event_type" },
          { text: "Descripción", value: "event_description" },
        ],
        true: [
          { text: "Fecha", value: "issue_date" },
          { text: "Evento", value: "event_type" },
          { text: "Descripción", value: "event_description" },
        ],
      },
      dialog_uuid: false,
      docs_dialog: false,
      doctype_dialog: false,
      docId: false,
      issue_date: null,
      cufe: "",
      cities: [],
      validDoc: false,
      doc: createDoc(),
      search: "",
      dialog_new: false,
      newItem: true,
      categories: [],
      subcategories: [],
      brands: [],
      status_lst: ["PENDIENTE", "DESPACHADO", "ENTREGADO", "CANCELADO", "ANULADO"],
      doc_type: { label: "" },
      docset: {
        label: "Seleccione un documento",
        value: "",
        to: "",
        concept: "",
        fp: [],
      },
      docType: docs_lst().docType,
      FP: [],
      f_required: [(v) => !!v || "Requerido"],
      f_valid_dates: [
        (v) => !!v || "El código es requerido",
        (v) => v >= getIssueDate(5) || "Fecha no valida",
        (v) =>
          v <= getIssueDate(-15) || "Máximo 15 días de antelación : " + getIssueDate(-15),
      ],
      delivery_times: [
        "6:00 a.m. - 10:00 a.m.",
        "10:00 a.m. - 02:00 p.m.",
        "02:00 a.m. - 06:00 p.m.",
      ],
      store: createClient(),
      connection: null,
      loading_status: false,
      loading_vendors: false,
      itemEdit: createItem(),
      editvalid: false,
      skuError: false,
      timeout: 250,
      timeout_error: 2000,
      edit_dialog: false,
      valid: false,
      productAdded: false,
      payvalid: false,
      search_valid: false,
      client_dialog: false,
      payment_dialogo: false,
      search_dialog: false,
      showPrint: false,
      filters: { categoria: "", marca: "", refe: "", id_pos: "" },
      sku: null,
      cart: [],
      payments: [],
      payRefe: false,
      payValue: false,
      pay_mode: null,
      payment: createPayment(),
      topay: true,
      discoutRule: (v) => {
        if (!v.trim()) return true;
        if (!isNaN(parseFloat(v)) && v >= 0 && v <= 50) return true;
        return "Debe ser un numero entre 0 y 50";
      },
      requiredRule: [(v) => (v && v !== null) || "Forma de pago requerida"],
      payRule: [
        (v) => !!v || "Forma de pago requerida",
        (v) =>
          (v && v <= this.totals.unpaid) ||
          "Valor max " + this.totals.unpaid.toLocaleString(2),
      ],
      id_lst: [
        { code: "13", name: "Cédula de ciudadanía" },
        { code: "31", name: "NIT" },
        { code: "11", name: "Registro civil" },
        { code: "12", name: "Tarjeta de identidad" },
        { code: "21", name: "Tarjeta de extranjería" },
        { code: "22", name: "Cédula de extranjería" },
        { code: "41", name: "Pasaporte" },
        { code: "42", name: "Documento de identificación extranjero" },
        { code: "50", name: "NIT de otro país" },
      ],
      CT: null,
      MR: null,
      colors: [],
      headers: [
        {
          text: "Producto / Servicio",
          align: "start",
          sortable: true,
          value: "product_name",
          dataType: "text",
        },
        {
          text: "Grupo",
          align: "start",
          sortable: true,
          value: "groupName",
          dataType: "text",
        },

        {
          text: "Marca",
          align: "start",
          sortable: true,
          value: "brand",
          dataType: "text",
        },
        {
          text: "Referencia",
          align: "start",
          sortable: true,
          value: "refe",
          dataType: "text",
        },
        {
          text: "Color",
          align: "start",
          sortable: true,
          value: "color",
          dataType: "text",
        },
        {
          text: "Talla",
          align: "start",
          sortable: true,
          value: "talla",
          dataType: "text",
        },
        {
          text: "Precio",
          align: "end",
          sortable: true,
          value: "precio",
          dataType: "number",
        },
        {
          text: "Descuento",
          align: "end",
          sortable: true,
          value: "descuento",
          dataType: "number",
        },
        { text: "", value: "actions", sortable: false },
      ],
      headers_cart: {
        false: [
          {
            text: "Descripción",
            align: "start",
            sortable: true,
            value: "product_name",
            dataType: "text",
          },

          {
            text: "Qty",
            align: "center",
            sortable: true,
            value: "qty",
            dataType: "text",
          },
          // {
          //   text: "price_value",
          //   align: "end",
          //   sortable: true,
          //   value: "price_value",
          //   dataType: "number",
          // },
          {
            text: "Price",
            align: "end",
            sortable: true,
            value: "tax_base",
            dataType: "number",
          },
          {
            text: "Tax",
            align: "end",
            sortable: true,
            value: "tax_value",
            dataType: "number",
          },

          {
            text: "Total",
            align: "end",
            sortable: true,
            value: "tItem",
            dataType: "number",
          },
        ],
        true: [
          {
            text: "Descripción",
            align: "start",
            sortable: true,
            value: "product_name",
            dataType: "text",
          },
          {
            text: "Qty",
            align: "center",
            sortable: true,
            value: "qty",
            dataType: "number",
            width: "5",
          },
          {
            text: "Valor",
            align: "end",
            sortable: true,
            value: "tItem",
            dataType: "number",
          },
        ],
      },
      headers_payments: [
        {
          text: "Forma de Pago",
          align: "start",
          value: "payment_concept",
          dataType: "text",
        },

        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "payment_value",
          dataType: "number",
        },
        { text: "", value: "actions", align: "end", sortable: false },
      ],
      headers_lst: {
        false: [
          {
            text: "Producto / Servicio",
            align: "start",
            sortable: true,
            value: "product_name",
            dataType: "text",
            groupable: false,
          },
          {
            text: "Pack",
            align: "end",
            sortable: true,
            value: "pack",
          },
          {
            text: "Valor",
            align: "end",
            sortable: true,
            value: "price",
            dataType: "number",
          },
        ],
        true: [
          {
            text: "Producto",
            align: "start",
            sortable: true,
            value: "product_name",
            dataType: "text",
          },
          {
            text: "Valor",
            align: "end",
            sortable: true,
            value: "price",
            dataType: "number",
          },
        ],
      },
      uom_lst: [
        { value: "UN", text: "Unidad" },
        { value: "GR", text: "Gramo" },
        { value: "KG", text: "Kilogramo" },
        { value: "LB", text: "Libra" },
        { value: "LT", text: "Litro" },
        { value: "ML", text: "Mililitro" },
        { value: "GL", text: "Galon" },
        { value: "OZ", text: "Onza" },
        { value: "Mes", text: "Mes" },
        { value: "Año", text: "Año" },
        { value: "Día", text: "Día" },
      ],
      inventory_lst: [],
      vendors: [],
      vendor: null,
      totals: { qty: 0, total: 0, tax: 0, paid: 0, unpaid: 0 },
      bags: 0,
      discount: null,
      dataURL: null,
      invoiceImg: null,
      st_items: [],
      itemsby: null,
      menus: null,
      menu: [],
      menu_selected: null,
      menuName: null,
      st_dialog: false,
      eInvoice: null,
      seller: null,
      dian_dialog: false,
      dian_response: dianResponse(),
      docview: "",
      dialog: false,
      smart_lst: [],
      suppliers: [],
      customToolbar: [
        // [{ font: [] }],
        // [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        //  [{ header: 1 }, { header: 2 }],
        //[{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
        //[{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      ],
      pdf: {
        header: { cufe: "" },
        items: [],
        payments: [],
        store: createClient(),
      },
    };
  },
  methods: {
    restQty() {
      if (this.itemEdit.qty > 1) {
        this.itemEdit.qty = this.itemEdit.qty - 1;
        this.updateTotal();
      }
    },
    onResize() {
      this.best_height = window.innerHeight;
      if (window.innerWidth < 480) {
        this.best_widht = window.innerWidth;
      }
    },
    addQty() {
      this.itemEdit.qty = this.itemEdit.qty + 1;
      this.updateTotal();
    },
    reset_doc() {
      this.doc = createDoc();
      this.doc.pos = this.$store.getters.company;
      this.doc.seller = this.$store.getters.user;
      // this.docType = docs_lst().docType;
      this.doctype_dialog = true;
    },
    show_events() {
      this.events_dialog = true;
      var qry = {
        account: this.$store.state.profile.account,
        table: "doc_events",
        filters: [
          { field: "account", operator: "=", value: this.$store.state.profile.account },
          { field: "doc_code", operator: "=", value: this.doc.doc_code },
        ],
      };

      this.loading_status = true;
      ////console.log(qry);
      webserver("get_dataTable", qry, (data) => {
        this.loading_status = false;
        this.events_lst = data;

        ////console.log(data);
      });
    },
    document_uuid(uuid) {
      this.uuid = uuid;
      this.dialog_uuid = true;
    },
    openURL(e) {
      window.open(
        "https://catalogo-vpfe.dian.gov.co/document/ShowDocumentToPublic/" + e,
        "",
        "width=400,height=800"
      );
    },
    changeDocType(dType) {
      ////console.log("changeDocType", dType);
      this.doctype_dialog = false;
      if (dType) {
        this.doc_type = dType;
      }
      this.docset = this.doc_type;
      this.doc.doc_type = this.doc_type.value;
      if (this.docId) {
        var doct = this.docId.split("_");
        if (doct[1]) {
          if (this.doc.doc_type === doct[1]) {
            this.doc.doc_code = this.docId;
            this.doc.issue_date = this.issue_date;
          } else {
            this.doc.doc_code = null;
            this.doc.issue_date = null;
          }
        }
        this.doctype_dialog = false;
      }
      this.updateTotal();
    },
    get_settings() {
      var qry = {
        account: this.$store.state.profile.account,
        table: "settings",
        filters: [
          {
            field: "tableName",
            operator: " IN ",
            value: "'municipalities','departments'",
          },
        ],
      };
      this.loading_status = true;
      fe_ws("get_table", qry, (data) => {
        this.loading_status = false;
        var ctgry = data.reduce(function (r, a) {
          r[a.tableName] = r[a.tableName] || [];
          r[a.tableName].push(a);
          return r;
        }, Object.create(null));
        ctgry["municipalities"].sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
        this.cities = ctgry["municipalities"];
        this.get_smart_lst();
      });
    },
    Wompi_status() {
      this.loading_status = true;
      var qry = {
        reference: this.doc.doc_code,
        account: this.doc.pos.account,
        store: this.doc.pos.code,
      };
      ////console.log(qry, this.doc);
      webserver("get_wompi_transactions", qry, () => {
        this.loading_status = false;
        this.get_doc(this.doc.doc_code);
        //console.log(data);
      });
    },
    party_submit() {
      this.doc.party.account = this.$store.state.profile.account;
      var qry = {
        account: this.$store.state.profile.account,
        table: "clients",
        data: [this.doc.party],
      };
      ////console.log(qry);
      webserver("put_table", qry, () => {
        //console.log(data);
        this.client_dialog = false;
      });
    },
    get_doc(doc_code) {
      this.loading_status = true;
      var qry = {
        table: "docs",
        doc_code: doc_code,
      };
      webserver("get_doc", qry, (data) => {
        this.set_doc(data);
      });
    },

    set_doc(data) {
      //console.log("set_doc", data);
      this.pdf = data;
      // if (this.pdf.header.cufe) {
      //   this.pdf.header.cufe = data.header.cufe;
      // } else {
      //   this.pdf.header.cufe = "";
      // }

      this.docId = data.header.doc_code;
      this.issue_date = data.header.issue_date;

      this.doc_type = this.docType.find((o) => o.value === data.header.doc_type);
      //console.log(this.doc_type, data.header.doc_type);

      this.doc = {
        account: null,
        store: null,
        userId: data.header.userId,
        agent: null,
        doc_type: data.header.doc_type,
        doc_code: data.header.doc_code,
        issue_date: data.header.issue_date,
        total: parseFloat(data.header.total),
        note: data.header.note,
        pos: data.store,
        party: createClient(),
        items: data.items,
        payments: data.payments,
        delivery_date: data.header.delivery_date,
        delivery_time: data.header.delivery_time,
        status: data.header.status,
      };
      this.doc.party.code = data.header.userId;
      this.doc.party.name = data.header.party_name;
      this.doc.party.type_id = data.header.party_type_id;
      this.doc.party.identification_id = data.header.party_identification_id;
      this.doc.party.address = data.header.party_address;
      this.doc.party.city = data.header.party_city;
      this.doc.party.mobile = data.header.party_phone;
      this.doc.party.email = data.header.party_email;
      this.doc.party.note = data.header.party_note;
      this.loading_status = false;
      this.updateTotal();
    },
    get_smart_lst() {
      this.items = [];
      this.loading_status = true;
      //console.log(this.$store.state.profile);
      var qry = {
        account: this.$store.state.profile.account,
        table: "raw_material",
        filters: [
          // { field: "store", operator: "=", value: this.$store.state.profile.store },
        ],
      };
      //console.log(qry);
      webserver("get_table", qry, (data) => {
        //console.log(data);
        this.loading_status = false;

        if (data.length !== 0) {
          data.forEach((rg) => {
            if (rg.cost_uom) rg.cost_uom = parseFloat(rg.cost_uom);
          });

          var ctgry = data.reduce(function (r, a) {
            r[a.category] = r[a.category] || [];
            r[a.category].push(a);
            return r;
          }, Object.create(null));

          for (const [key] of Object.entries(ctgry)) {
            ctgry[key].sort(function (a, b) {
              var textA = a.product_name.toUpperCase();
              var textB = b.product_name.toUpperCase();
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
          }
          this.byCategories = ctgry;
          this.categories = Object.keys(ctgry).sort();

          var sbctgry = data.reduce(function (r, a) {
            r[a.subcategory] = r[a.subcategory] || [];
            r[a.subcategory].push(a);
            return r;
          }, Object.create(null));
          this.subcategories = Object.keys(sbctgry).sort();

          var brnd = data.reduce(function (r, a) {
            r[a.brand] = r[a.brand] || [];
            r[a.brand].push(a);
            return r;
          }, Object.create(null));
          this.brands = Object.keys(brnd).sort();
          //this.smart_lst = ctgry;
        }

        this.loading_status = false;
      });
    },
    // get_smart_lst() {
    //   var qry = {
    //     account: this.$store.state.profile.account,
    //   };
    //   this.loading_status = true;
    //   //console.log(qry);
    //   webserver("get_bestprice", qry, (data) => {
    //     //console.log("get_bestprice", data);
    //     data.forEach((rg) => {
    //       delete rg.codelp;
    //       delete rg.costlp;
    //       delete rg.cost_max;
    //       delete rg.cost_avg;
    //       delete rg.sup_qty;
    //       delete rg.utility;
    //       delete rg.bp_code;
    //       rg.qty = parseFloat(rg.qty);
    //       rg.pack = parseFloat(rg.pack);
    //       rg.cost = parseFloat(rg.cost);
    //       rg.price = parseFloat(rg.price);
    //       rg.discount = parseFloat(rg.discount);

    //       var tax_value = parseFloat(rg.tax) / 100 + 1;

    //       rg.discount_value = (
    //         (parseFloat(rg.price) * parseFloat(rg.discount)) /
    //         100
    //       ).toFixed(2);

    //       rg.price_value = (parseFloat(rg.price) - parseFloat(rg.discount_value)).toFixed(
    //         2
    //       );

    //       rg.price_amount = Math.round(parseFloat(rg.price_value) / tax_value).toFixed(2);

    //       rg.tax_value = Math.round(
    //         (parseFloat(rg.price_amount) *
    //           parseFloat(rg.qty) *
    //           parseFloat(rg.pack) *
    //           rg.tax) /
    //           100
    //       ).toFixed(2);

    //       rg.tItem = (
    //         parseFloat(rg.price_amount) * parseFloat(rg.qty) * parseFloat(rg.pack) +
    //         parseFloat(rg.tax_value)
    //       ).toFixed(2);
    //     });

    //     var ctgry = data.reduce(function (r, a) {
    //       r[a.category] = r[a.category] || [];
    //       r[a.category].push(a);
    //       return r;
    //     }, Object.create(null));

    //     for (const [key] of Object.entries(ctgry)) {
    //       ctgry[key].sort(function (a, b) {
    //         var textA = a.product_name.toUpperCase();
    //         var textB = b.product_name.toUpperCase();
    //         return textA < textB ? -1 : textA > textB ? 1 : 0;
    //       });
    //     }
    //     this.menu = ctgry;
    //     //console.log("======>", this.menu);
    //     this.smart_categories = Object.keys(ctgry).sort();
    //     this.smart_lst_selected = data;

    //     ////console.log(ctgry, data);
    //     this.loading_status = false;
    //     this.smart_lst = data;
    //     //this.changeMenu();
    //   });
    // },
    get_books(books) {
      var qry = {
        account: this.$store.state.profile.account,
        table: "accounting_puc",
        filters: [
          { field: "left(puc_code,1)", operator: " IN ", value: books },
          { field: "LENGTH(puc_code)", operator: ">", value: "6" },
        ],
      };

      this.loading_status = true;
      //console.log(qry);
      webserver("get_dataTable", qry, () => {
        //console.log(data);
      });
    },
    updateTotal() {
      this.totals.qty = 0;
      this.totals.total = 0;
      this.totals.tax = 0;
      this.totals.paid = 0;
      this.totals.unpaid = 0;
      this.totals.costs = 0;
      this.doc.total = 0;
      this.doc.subtotal = 0;
      this.doc.tax = 0;
      this.doc.qty = 0;

      this.doc.items.forEach((itm) => {
        itm.qty = parseFloat(itm.qty);
        itm.pack = parseFloat(itm.pack);
        itm.cost = parseFloat(itm.cost);
        itm.price = parseFloat(itm.price);
        itm.discount = parseFloat(itm.discount);
        var tax_value = parseFloat(itm.tax) / 100 + 1;
        if (this.doc.doc_type !== "ORP") {
          itm.discount_value = (
            (parseFloat(itm.price) * parseFloat(itm.discount)) /
            100
          ).toFixed(2);

          itm.price_value = (
            parseFloat(itm.price) - parseFloat(itm.discount_value)
          ).toFixed(2);

          itm.price_amount = Math.round(parseFloat(itm.price_value) / tax_value).toFixed(
            2
          );

          itm.tax_value = Math.round(
            (parseFloat(itm.price_amount) *
              parseFloat(itm.qty) *
              parseFloat(itm.pack) *
              itm.tax) /
              100
          ).toFixed(2);

          itm.tItem = (
            parseFloat(itm.price_amount) * parseFloat(itm.qty) * parseFloat(itm.pack) +
            parseFloat(itm.tax_value)
          ).toFixed(2);

          itm.tax_base =
            parseFloat(itm.price_amount) * parseFloat(itm.qty) * parseFloat(itm.pack);
        } else {
          itm.discount_value = 0;
          itm.price_value = parseFloat(itm.cost);
          itm.price_amount = Math.round(parseFloat(itm.price_value) / tax_value).toFixed(
            2
          );
          itm.tax_value = Math.round(
            (parseFloat(itm.price_amount) *
              parseFloat(itm.qty) *
              parseFloat(itm.pack) *
              itm.tax) /
              100
          ).toFixed(2);
          itm.tItem = (
            parseFloat(itm.price_amount) * parseFloat(itm.qty) * parseFloat(itm.pack) +
            parseFloat(itm.tax_value)
          ).toFixed(2);
          itm.tax_base =
            parseFloat(itm.price_amount) * parseFloat(itm.qty) * parseFloat(itm.pack);
        }

        this.totals.qty += parseFloat(itm.qty);
        this.totals.total += parseFloat(itm.tItem);
        this.totals.tax += parseFloat(itm.tax_value);
        // this.totals.costs +=
        //   parseFloat(itm.qty) * parseFloat(itm.pack) * parseFloat(itm.cost);
        this.doc.qty += parseFloat(itm.qty);
        this.doc.total += parseFloat(itm.tItem);
        this.doc.tax += parseFloat(itm.tax_value);
        this.doc.subtotal += parseFloat(itm.tax_base);
      });
      this.doc.payments.forEach((itm) => {
        this.totals.paid += parseFloat(itm.payment_value);
      });
      this.totals.unpaid = this.totals.total - this.totals.paid;
      this.payment.payment_value = this.totals.unpaid;

      if (this.totals.unpaid === 0 && this.totals.total > 0) {
        this.payment_dialogo = false;
        this.topay = true;
        //this.setSku();
        //this.save_invoice();
      } else {
        this.topay = false;
      }
    },
    print_invoice() {
      var doc = this.pdf;
      //console.log(this.pdf);
      if (doc.header) {
        var pdf = new jsPDF({
          orientation: "p",
          unit: "mm",
          format: "letter",
        });
        var LS = 5;
        var y = 20;
        var x = 20;
        pdf.setFontType = "bold";
        pdf.setFontSize(14);
        pdf.text(20, y, "NGM Colombia SAS", "left");
        pdf.setFontType = "normal";
        pdf.setFontSize(8);
        y += LS;
        pdf.text(
          20,
          y,
          this.id_lst.find((item) => item.code == doc.store.type_id).name +
            " " +
            doc.store.identification_id,
          "left"
        );
        y += LS;
        pdf.text(20, y, doc.store.address, "left");
        y += LS;
        pdf.text(20, y, doc.store.city + ", " + doc.store.province, "left");
        y = 48;
        pdf.setFontSize(8);
        pdf.text(
          20,
          y,
          this.docType.find((item) => item.value == doc.header.doc_type).label +
            " : " +
            doc.header.doc_code,
          "left"
        );
        pdf.text(160, y, "Fecha: " + doc.header.issue_date, "left");
        y += LS;
        pdf.text(160, y, "Estado: " + doc.header.status, "left");
        y += LS;
        y += 2;
        pdf.setLineWidth(0.1);
        pdf.setDrawColor(0, 102, 0);
        // pdf.line(20, y, 200, y);
        y += LS;
        y += LS;
        y = 20;
        pdf.setFontSize(14);
        pdf.text(120, y, doc.header.party_name, "left");
        pdf.setFontType = "normal";
        pdf.setFontSize(8);
        y += LS;
        pdf.text(
          120,
          y,
          this.id_lst.find((item) => item.code == doc.header.party_type_id).name +
            " " +
            doc.header.party_identification_id,
          "left"
        );
        y += LS;

        pdf.text(120, y, doc.header.party_address, "left");
        y += LS;
        pdf.text(120, y, doc.header.party_city, "left");
        y += LS;
        pdf.text(
          120,
          y,
          doc.header.party_email + " Tel: " + doc.header.party_phone,
          "left"
        );
        y += LS;
        pdf.text(120, y, doc.header.party_note, "left");
        y += LS;
        y += LS;
        y = 60;
        pdf.setFontSize(10);
        pdf.setFontType = "normal";
        pdf.text(50, y, "Producto", "center");
        pdf.text(130, y, "Cant.", "center");
        pdf.text(150, y, "Precio", "right");
        pdf.text(170, y, "Impuesto", "right");
        pdf.text(200, y, "Total", "right");
        // y += 2;
        // pdf.line(20, y, 200, y);
        var temY = y;
        pdf.roundedRect(15, temY - 5, 190, 140, 1, 1);
        y += LS;
        doc.items.forEach((row) => {
          pdf.setFontType = "bold";
          pdf.setFontSize(8);
          pdf.text(20, y, row.product_name);
          pdf.setFontType = "normal";
          pdf.text(130, y, row.qty.toLocaleString(), "center");
          pdf.text(150, y, parseFloat(row.tax_base).toLocaleString(2), "right");
          pdf.text(170, y, parseFloat(row.tax_value).toLocaleString(2), "right");
          pdf.text(200, y, "$ " + parseFloat(row.tItem).toLocaleString(2), "right");
          y += LS;
          if (row.shipping_time > 1) {
            pdf.text(20, y, "Tiempo de entrega : " + row.shipping_time + " dias hábiles");
            y += LS;
          }

          // pdf.setFontSize(6);
          // var dtext = row.description.replace(/<[^>]+>/g, "");
          // let regex = /&(nbsp|amp|quot|lt|gt);/g;
          // dtext = dtext.replace(regex, " ");
          // var splitTitle = pdf.splitTextToSize(dtext, 105);
          // pdf.text(20, y, splitTitle);
          // y += 3 * splitTitle.length - 1;
          //pdf.text(x, y, dtext);
        });
        y = 200;
        temY = y;
        pdf.setFillColor(255, 255, 200);
        pdf.roundedRect(15, y - 2, 190, 20, 1, 1);
        doc.payments.forEach((row) => {
          y += LS;
          x = 20;
          pdf.text(x, y, row.payment_concept, "left");
          x = 120;
          pdf.text(x, y, "$ " + parseFloat(row.payment_value).toLocaleString(2), "right");
          y += LS;
          x = 30;
          if (row.description) {
            var dtext = row.description.replace(/<[^>]+>/g, "");
            var splitTitle = pdf.splitTextToSize(dtext, 150);
            pdf.text(x, y, splitTitle);
            y += LS;
          }
        });
        y = temY;
        y += LS;
        x = 150;
        pdf.setFontType = "bold";
        pdf.text(x, y, "Sub-Total");
        x = 200;
        pdf.text(
          x,
          y,
          "$ " +
            (parseFloat(doc.header.total) - parseFloat(doc.header.tax)).toLocaleString(2),
          "right"
        );
        y += LS;
        x = 150;
        pdf.text(x, y, "Impuesto");
        x = 200;
        pdf.text(x, y, "$ " + parseFloat(doc.header.tax).toLocaleString(2), "right");
        y += LS;
        x = 150;
        pdf.text(x, y, "TOTAL");
        x = 200;
        pdf.text(x, y, "$ " + parseFloat(doc.header.total).toLocaleString(2), "right");
        // y += 2;
        // pdf.line(20, y, 200, y);
        y += LS;
        y += LS;
        temY = y;
        pdf.setFontSize(8);
        var note = pdf.splitTextToSize(
          "El presente documento presta merito ejecutivo y se asemeja a una letra de cambio.",
          85
        );
        pdf.text(115, y, note);
        pdf.text(20, y, "Observaciones");
        note = pdf.splitTextToSize(doc.header.note, 85);
        y += LS;
        pdf.text(20, y, note);
        // pdf.setFontSize(8);
        y += LS;
        y += LS;
        y += LS;
        y += LS;
        // y += 2;
        // pdf.line(40, y, 120, y);
        y += LS;
        pdf.text(115, y, "ACEPTO : " + doc.header.party_name);
        y += LS;
        pdf.text(
          115,
          y,
          this.id_lst.find((item) => item.code == doc.header.party_type_id).name +
            ": " +
            doc.header.party_identification_id
        );
        pdf.roundedRect(112, temY - 5, 93, y - temY + 8, 1, 1);
        pdf.roundedRect(15, temY - 5, 93, y - temY + 8, 1, 1);
        pdf.setTextColor(0, 183, 0);
        var today = new Date();
        pdf.text(10, 100, "Date Printed : " + today.toLocaleString(), null, 90);
        // var out = pdf.output();
        // var url = "data:application/pdf;base64," + btoa(out);
        // this.pdfFile = url;
        //this.pdfFile = pdf.output('datauri');
        pdf.save(doc.header.doc_code + "_" + doc.header.party_name + ".pdf");
      }
    },
    newProduct(e) {
      this.dialog_new = false;
      this.add2cart(e);
      this.get_smart_lst();
    },
    set_to() {
      //this.doc.to = doc_to;
      //console.log(this.doc);
    },
    changeStatus() {
      var document = this.doc;
      document.account = this.$store.getters.company.account;
      document.store = this.$store.getters.company.code;
      document.userId = this.doc.party.code;
      document.agent = this.$store.getters.profile.name;
      document.party.city = this.doc.party.city;
      //console.log(document);
      webserver("put_doc", document, () => {
        //console.log(data);
        this.$store.dispatch("setToast", {
          timeout_error: 2000,
          dialog: true,
          text: "Documento actualizado",
          color: "green",
        });
        this.$router.push("/docs");
      });
    },
    send_doc(e) {
      this.updateTotal();
      e.preventDefault();
      if (!this.doc.party.identification_id) {
        this.$store.dispatch("setToast", {
          timeout_error: 2000,
          dialog: true,
          text: "Debe suminitrar un cliente",
          color: "red",
        });
      }
      this.validDoc = this.$refs.docForm.validate();
      if (this.validDoc && this.doc.party.identification_id) {
        // this.doc.total = this.totals.total;
        var document = this.doc;
        this.loading_status = true;
        document.account = this.$store.getters.company.account;
        document.store = this.$store.getters.company.code;
        document.userId = this.doc.party.code;
        document.agent = this.$store.getters.profile.name;
        document.party.city = this.doc.party.city;
        //console.log(document);

        webserver("put_doc", document, (data) => {
          this.set_doc(data);
          this.loading_status = false;
          //this.$router.push({ name: "Checkout", params: { did: data.doc_code } });
        });
      }
    },
    sumField(fld, key) {
      var dta = fld;
      return dta.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
    },
    sumFieldPrice(fld, key) {
      var dta = fld;
      return dta.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
    },
    getImagePhoto: function (account, src) {
      var currentdate = new Date();
      var url =
        "https://" +
        this.$store.getters.getHost +
        "/" +
        account +
        "/" +
        src +
        "?" +
        currentdate;
      return url;
    },
    getImageURL: function (marca, refe) {
      var account = window.store.store_id.substring(0, 6);
      var url =
        "https://moda-stc.com/photos/" + account + "/" + marca + "/" + refe + ".jpg";
      if (this.compressed_image) url = this.compressed_image;
      return url;
    },
    scroll() {
      document.getElementById("scroll-target").scrollTop = document.getElementById(
        "scroll-target"
      ).scrollHeight;
    },
    editItem(e) {
      //console.log(e);
      this.itemEdit = e;
      this.edit_dialog = true;
    },
    itemUpdate(e) {
      e.preventDefault();
      this.$refs.editform.validate();
      if (this.editvalid) {
        //console.log("Saving");
      }
    },
    get_name_typeId(e) {
      var index = this.id_lst.findIndex((itm) => itm.code === e);
      if (index >= 0) {
        return this.id_lst[index].name;
      }
    },
    // restQty() {
    //   if (this.bags > 0) {
    //     this.bags = this.bags - 1;
    //   }
    //   this.setSku();
    // },
    // addQty() {
    //   this.bags = this.bags + 1;
    //   this.setSku();
    // },
    get_product(e) {
      e.preventDefault();
      this.loading_status = true;
      var qry = {
        store: window.store.store_id,
        dbSearch: "inventory",
        filters: {
          id_pos: window.store.store_id,
          ean: this.sku,
        },
      };
      this.sku = null;
      webserver("get_catalog", qry, (data) => {
        this.loading_status = false;
        if (data[0]) {
          this.add2cart(data[0]);
        } else {
          this.skuError = true;
        }
      });
    },
    get_id_barcode(e) {
      //var temp = idCliente.split('\t');
      var temp = e.split(";");
      var u_id = temp[0].trim();
      var u_lastname = (temp[1].trim() + " " + temp[2].trim()).trim();
      var u_firstname = (temp[3].trim() + " " + temp[4].trim()).trim();
      var u_name = (u_firstname + " " + u_lastname).trim();
      var birthday = null;
      var sx = null;
      if (temp[5] === "0M" || temp[5] === "0F") {
        sx = temp[5].replace(/0/g, "");
        birthday =
          temp[6].substring(0, 4) +
          "-" +
          temp[6].substring(4, 6) +
          "-" +
          temp[6].substring(6, 8);
      }
      if (temp[5] === "M" || temp[5] === "F") {
        sx = temp[5];
        birthday =
          temp[6].substring(4, 8) +
          "-" +
          temp[6].substring(2, 4) +
          "-" +
          temp[6].substring(0, 2);
      }
      this.client.PartyIdentification = u_id;
      this.client.PartyName = u_name;
      this.client.client_birthday = birthday;
      this.client.sex = sx;
    },
    validateID(e) {
      if (e.keyCode === 13) {
        this.loading_status = true;
        var qry = {
          account: this.store.account,
          table: "clients",
          filters: [
            {
              field: "identification_id",
              operator: "=",
              value: this.doc.party.identification_id,
            },
          ],
        };
        //console.log(qry);
        webserver("get_table", qry, (data) => {
          //console.log(data);
          this.loading_status = false;
          if (data[0]) {
            this.doc.party = data[0];
          }
        });
      }
    },
    get_client(e) {
      var qry = {
        account: this.store.account,
        table: "clients",
        filters: [
          {
            field: "mobile",
            operator: "=",
            value: e,
          },
        ],
      };
      //console.log(qry);
      webserver("get_table", qry, (data) => {
        if (data[0]) {
          this.doc.party = data[0];
        }
      });
    },
    add2cart(e) {
      e.qty = 1;
      e.doc_code = this.doc.doc_code;
      e.order_id = null;
      this.doc.items.push({ ...e });
      this.updateTotal();
      //console.log(this.doc.items);
    },
    delete_payment(e) {
      var index = this.doc.payments.indexOf(e);
      if (index !== -1) {
        this.doc.payments.splice(index, 1);
      }
      this.updateTotal();
      this.setSku();
    },
    delete_item(e) {
      var index = this.doc.items.indexOf(e);
      if (index !== -1) {
        this.doc.items.splice(index, 1);
      }
      this.setSku();
      this.updateTotal();
      this.edit_dialog = false;
    },
    addPayment(e) {
      e.preventDefault();
      if (this.pay_mode) {
        if (this.pay_mode.codigo === "01") {
          if (this.payment.payment_value > this.totals.unpaid) {
            var cmb =
              parseFloat(this.payment.payment_value) - parseFloat(this.totals.unpaid);
            this.payment.payment_concept = this.pay_mode.valor;
            this.payment.payment_code = this.pay_mode.codigo;
            this.payment.payment_value = this.totals.unpaid;
            this.doc.payments.push({ ...this.payment });

            var cambio = {
              payment_concept: "EFECTIVO",
              payment_code: "01",
              payment_reference: " Cambio $ " + cmb,
              payment_value: 0,
            };
            this.doc.payments.push({ ...cambio });
            this.updateTotal();
          } else {
            this.payment.payment_concept = this.pay_mode.valor;
            this.payment.payment_code = this.pay_mode.codigo;
            this.doc.payments.push({ ...this.payment });
            this.updateTotal();
          }
          // this.pay_mode = {
          //   codigo: "01",
          //   valor: "EFECTIVO",
          // };
        } else {
          if (this.payment.payment_value <= this.totals.unpaid) {
            //console.log(this.pay_mode);
            this.payment.payment_concept = this.pay_mode.valor;
            this.payment.payment_code = this.pay_mode.codigo;
            this.doc.payments.push({ ...this.payment });
            this.updateTotal();
            // this.pay_mode = {
            //   codigo: "01",
            //   valor: "EFECTIVO",
            // };
          } else {
            this.payValue = true;
            this.doc.payment.payment_value = 0;
          }
        }
      }
    },
    updateRefe() {
      if (this.payment.payment_reference.length > 0) {
        this.payRefe = false;
      }
    },
    get_vendors() {
      var qry = {
        store: window.store.store_id,
      };
      this.loading_vendors = true;
      webserver("vendors", qry, (data) => {
        this.loading_vendors = false;
        //console.log(data);
        data.forEach((itm) => {
          itm.nombre = itm.nombre.toUpperCase();
        });
        window.vendors = data;
        this.vendors = data;
      });
    },
    setSku() {
      // setTimeout(() => {
      //   document.getElementById("sku").focus();
      // }, 50);
    },
    resetInvoice() {
      this.filters = { categoria: "", marca: "", refe: "", id_pos: "" };
      this.bags = 0;
      this.doc.items = [];
      this.client = createClient();
      this.payment = createPayment();
      this.payments = [];
    },
    print_doc() {
      var a = window.open("", "", "height=600, width=400");
      a.document.write('<img src="' + this.dataURL + '"  width="100%">');
      a.print();
      a.document.close();
      setTimeout(() => {
        a.close();
      }, 500);
    },
    search_inventory(e) {
      e.preventDefault();
      this.inventory_lst = [];
      this.loading_status = true;
      var qry = {
        account: this.store.account,
        table: "rowmaterial",
        filters: [{ field: "store", operator: "=", value: "000000" }],
      };
      webserver("get_table", qry, (data) => {
        this.inventory_lst = data;
        this.loading_status = false;
      });
    },
    get_store_ls() {
      var qry = {
        store: window.store.store_id,
      };
      webserver("get_inventory_st", qry, (data) => {
        this.loading_status = false;
        this.st_items = data;
      });
    },
    changeMenu() {
      // this.menu_selected = this.itemsby[e];
      // //console.log(this.menu_selected);
      // this.menuName = e;
    },
    get_options(e) {
      return this.groupBy(e.split(","));
    },
    groupBy(arr) {
      var group = [];
      var option = { ean: null, description: null };
      for (let i = 0; i < arr.length - 1; i += 3) {
        //console.log(arr[i]);
        var color = "";
        if (this.colors[arr[i + 1]]) {
          color = this.colors[arr[i + 1]].valor;
        } else {
          color = arr[i + 1];
        }
        option = { ean: arr[i], description: color + " - " + arr[i + 2] };
        group.push(option);
        ////console.log(option);
      }
      return group;
    },
    save_client() {
      var qry = {
        store: window.store.store_id,
        party: this.client,
      };
      webserver("put_client_info", qry, () => {
        //this.resetDoc();
        //this.loading_vendors = false;
        //console.log(data);
        //this.print_doc(data);
      });
    },
    e_invoice() {
      //console.log("######", this.doc);
      //var custumer = this.doc.party;
      //var itms = this.doc.items;
      //var doc = this.doc;
      var payments = this.doc.payments;
      var eInv = {
        number: this.doc.doc_code.split("-")[1],
        sync: true,
        type_document_id: 1,

        customer: {
          identification_number: this.doc.party.identification_id,
          name: this.doc.party.name,
          municipality_id: this.cities.find((item) => item.code == this.doc.party.city)
            .id,
          email: this.doc.party.email,
        },
        legal_monetary_totals: {
          line_extension_amount: 0,
          tax_exclusive_amount: 0,
          tax_inclusive_amount: 0,
          allowance_total_amount: 0,
          charge_total_amount: 0,
          payable_amount: 0,
        },
        invoice_lines: [],
      };

      //var taxvalue = 0.0;

      this.doc.items.forEach((item) => {
        item.qty = parseFloat(item.qty);
        var price = parseFloat(item.price) - parseFloat(item.discount);

        var tax_value = item.tax / 100 + 1;

        item.price_amount = Math.round(parseFloat(price) / tax_value).toFixed(2);
        item.discount = parseFloat(item.discount).toFixed(2);
        item.taxes = Math.round(
          (parseFloat(item.price_amount) *
            parseFloat(item.qty) *
            parseFloat(item.pack) *
            item.tax) /
            100
        ).toFixed(2);
        //Valor total de la línea (Cantidad x Precio Unidad menos descuentos más recargos que apliquen para la línea)
        item.line_extension_amount = (
          parseFloat(item.price_amount) *
          parseFloat(item.qty) *
          parseFloat(item.pack)
        ).toFixed(2);
      });

      this.doc.items.forEach((item) => {
        var itm = {
          description: item.description,
          unit_measure_id: 642,
          code: item.code,
          type_item_identification_id: 3,
          base_quantity: item.qty.toFixed(6),
          invoiced_quantity: (item.qty * item.pack).toFixed(6),
          price_amount: item.price_amount,
          line_extension_amount: item.line_extension_amount,
        };
        if (parseFloat(item.discount) > 0) {
          itm.allowance_charges = [
            {
              charge_indicator: false,
              allowance_charge_reason: "Discount",
              amount: parseFloat(item.discount),
              base_amount: (
                parseFloat(item.price_amount) *
                parseFloat(item.qty) *
                parseFloat(item.pack)
              ).toFixed(2),
            },
          ];
          eInv.legal_monetary_totals.allowance_total_amount +=
            parseFloat(item.discount) * parseFloat(item.qty) * parseFloat(item.pack);
        }
        itm.tax_totals = [
          {
            tax_id: 1,
            tax_amount: item.taxes,
            taxable_amount: (
              parseFloat(item.price_amount) *
              parseFloat(item.qty) *
              parseFloat(item.pack)
            ).toFixed(2),
            percent: parseFloat(item.tax).toFixed(2),
          },
        ];
        eInv.legal_monetary_totals.line_extension_amount += parseFloat(
          itm.line_extension_amount
        ); //Valores totales aplicables a la factura
        eInv.legal_monetary_totals.tax_exclusive_amount += parseFloat(
          item.line_extension_amount
        ); // Total Valor Bruto (Antes de tributos)
        eInv.legal_monetary_totals.tax_inclusive_amount +=
          parseFloat(item.line_extension_amount) + parseFloat(item.taxes);
        eInv.legal_monetary_totals.charge_total_amount +=
          parseFloat(item.line_extension_amount) + parseFloat(item.taxes);
        eInv.legal_monetary_totals.payable_amount +=
          parseFloat(item.line_extension_amount) + parseFloat(item.taxes);
        eInv.invoice_lines.push(itm);
      });

      var bags = payments.find((item) => item.tipo == "ICB ley 1819 de 2016");

      if (bags) {
        //console.log("bags ->>>", bags);
        bags.quantity = parseFloat(bags.valor).toFixed(2) / 51;
        bags.tax = 51;
        var itmBags = {
          unit_measure_id: 886,
          invoiced_quantity: bags.quantity.toFixed(6),
          line_extension_amount: "0.00",
          reference_price_id: 1,
          tax_totals: [
            {
              tax_id: 10,
              tax_amount: parseFloat(bags.valor).toFixed(2),
              taxable_amount: bags.quantity.toFixed(2),
              unit_measure_id: 886,
              per_unit_amount: bags.tax.toFixed(2),
              base_unit_measure: "1.000000",
            },
          ],
          description: "Bolsas",
          code: "000000000000",
          type_item_identification_id: 3,
          price_amount: bags.tax.toFixed(2),
          base_quantity: "1.000000",
        };
        eInv.legal_monetary_totals.line_extension_amount += parseFloat(
          itmBags.line_extension_amount
        ); //Valores totales aplicables a la factura
        eInv.legal_monetary_totals.tax_exclusive_amount += parseFloat(
          itmBags.line_extension_amount
        ); // Total Valor Bruto (Antes de tributos)
        eInv.legal_monetary_totals.tax_inclusive_amount +=
          parseFloat(itmBags.line_extension_amount) + parseFloat(bags.tax);
        eInv.legal_monetary_totals.charge_total_amount +=
          parseFloat(itmBags.line_extension_amount) + parseFloat(bags.tax);
        eInv.legal_monetary_totals.payable_amount +=
          parseFloat(itmBags.line_extension_amount) + parseFloat(bags.tax);
        eInv.invoice_lines.push(itmBags);
      }

      eInv.legal_monetary_totals.line_extension_amount = eInv.legal_monetary_totals.line_extension_amount.toFixed(
        2
      ); //Valores totales aplicables a la factura
      eInv.legal_monetary_totals.tax_exclusive_amount = eInv.legal_monetary_totals.tax_exclusive_amount.toFixed(
        2
      ); // Total Valor Bruto (Antes de tributos)
      eInv.legal_monetary_totals.tax_inclusive_amount = eInv.legal_monetary_totals.tax_inclusive_amount.toFixed(
        2
      );
      //eInv.legal_monetary_totals.allowance_total_amount = eInv.legal_monetary_totals.allowance_total_amount.toFixed(2);
      eInv.legal_monetary_totals.charge_total_amount = eInv.legal_monetary_totals.charge_total_amount.toFixed(
        2
      );
      eInv.legal_monetary_totals.payable_amount = eInv.legal_monetary_totals.payable_amount.toFixed(
        2
      );
      //console.log(eInv);

      this.put_invoice(eInv);
    },
    put_invoice(data) {
      var qry = {
        method: "POST",
        nit: this.$store.getters.company.identification_id.replace("-", ""),
        data: data,
        account: this.store.account,
        issueDate: this.doc.issue_date,
        did: this.doc.doc_code,
      };
      //console.log("---->", qry);
      this.loading_status = true;
      fe_ws("post_invoice", qry, (data) => {
        this.loading_status = false;
        //console.log(JSON.parse(data.data));
        var dian_response = JSON.parse(data.data);
        // //console.log(dian_response);
        if (dian_response.cufe) {
          this.cufe = dian_response.cufe;
        }
        // this.doc = createDoc();
        // this.get_doc(qry.did);
        this.dian_dialog = true;
        // this.dian_response = JSON.parse(data.data);
      });
    },
  },
  watch: {
    bags: function () {
      var idx = this.payments.findIndex((itm) => itm.payment_code === "00");
      if (idx === -1) {
        this.payments.push({
          payment_code: "00",
          payment_concept: "ICB ley 1819 de 2016",
          payment_reference: "",
          payment_value: 51 * this.bags,
        });
      } else {
        this.payments[idx].payment_value = 51 * this.bags;
        if (this.bags <= 0) {
          this.payments.splice(idx, 1);
        }
      }
      idx = this.payments.findIndex((itm) => itm.payment_code === "000");
      if (idx === -1) {
        this.payments.push({
          payment_code: "000",
          payment_concept: "Descuento Impuesto Bolsas",
          payment_reference: "",
          payment_value: -51 * this.bags,
        });
      } else {
        this.payments[idx].payment_value = -51 * this.bags;
        if (this.bags <= 0) {
          this.payments.splice(idx, 1);
        }
      }

      if (this.bags === 0) {
        idx = this.payments.findIndex((itm) => itm.payment_code === "00");
        this.payments.splice(idx, 1);
        idx = this.payments.findIndex((itm) => itm.payment_code === "000");
        this.payments.splice(idx, 1);
        this.updateTotal();
      }
    },
    client_dialog(visible) {
      if (!visible) {
        ////console.log(visible);
        this.setSku();
      }
    },
    pay_mode() {
      if (this.pay_mode.codigo === "01") {
        this.payRefe = false;
      } else {
        this.payRefe = true;
      }
      ////console.log(this.payRefe);
    },
    payment() {
      //console.log(e);
    },
    st_items: function (val) {
      var res = val.reduce(function (r, a) {
        r[a.category] = r[a.category] || [];
        r[a.category].push(a);
        return r;
      }, Object.create(null));
      this.itemsby = res;
      this.menus = Object.keys(res);
      if (this.menuName) {
        this.menu_selected = res[this.menuName];
      } else {
        this.menu_selected = res[Object.keys(res)[0]];
      }
    },
  },
  updated() {
    //this.scroll();
  },
  computed: {},
};
</script>

<style lang="scss">
.right-input input {
  text-align: right;
  width: 10px;
}
.v-item-group.v-bottom-navigation {
  box-shadow: none;
}

.v-btn__content {
  width: 100% !important;
  white-space: normal !important;
}
.pos {
  z-index: 9999 !important;
}
</style>
